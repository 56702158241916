import { PageHeaderProperties } from 'components/PageHeader';
import { MaxWidth } from 'components/MaxWidth';
import styles from './LandingPage.module.css';
import { ReactNode } from 'react';

interface Properties extends PageHeaderProperties {
  children: ReactNode;
}

export const LandingPage = ({ children }: Properties) => (
  <div className={styles.content}>
    <MaxWidth size="medium">{children}</MaxWidth>
  </div>
);
