import { LandingPage } from 'components/LandingPage';
import { PublicAndTypeStaticProperties } from 'utils/interfaces';
import type { GetStaticProps, NextPage } from 'next';
import { VerticalMargin } from 'components/VerticalMargin';
import { RichText } from 'components/RichText';
import { ButtonGroup } from 'components/ButtonGroup';
import { ROUTES } from 'constants/ROUTES';

export const getStaticProps: GetStaticProps<PublicAndTypeStaticProperties> = () => ({
  props: { isPublicPage: true, pageTransparentHeader: true },
});

const Home: NextPage = () => (
  <LandingPage>
    <RichText center>
      <h1>Waar ben je naar op zoek?</h1>
    </RichText>
    <VerticalMargin />
    <ButtonGroup
      center
      buttons={[
        { children: 'Ik zoek een baan', href: ROUTES.werknemerHome, theme: 'secondary' },
        { children: 'Ik zoek personeel', href: ROUTES.werkgeverHome, theme: 'secondary' },
      ]}
    />
  </LandingPage>
);

export default Home;
