import classNames from 'classnames';
import { ReactNode, useMemo } from 'react';
import styles from './MaxWidth.module.css';

interface Properties {
  children: ReactNode;
  size?: 'small' | 'medium' | 'large';
  className?: string;
  noMobilePadding?: boolean;
  noPadding?: boolean;
}

export const MaxWidth = ({
  children,
  size = 'large',
  className,
  noMobilePadding = false,
  noPadding,
}: Properties): JSX.Element => {
  const containerClassName = useMemo(
    () =>
      classNames(styles.container, styles[size], className, {
        [styles.noMobilePadding]: noMobilePadding,
        [styles.noPadding]: noPadding,
      }),
    [className, noMobilePadding, noPadding, size]
  );

  return <div className={containerClassName}>{children}</div>;
};
